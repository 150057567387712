@import 'variables.scss';

.form-md {
  max-width: 400px;
}

.form-lg {
  max-width: 600px;
}

.form-xl {
  max-width: 800px;
}

.form-centered {
  margin: 0 auto;
}

.textarea-label {
  textarea {
    margin-top: 50px !important;
  }
}

// fixes x-axis overflow bug
// incorporate globaly if doesn't cause issues
.short-label {
  label {
    width: auto !important;
  }
}

.label {
  line-height: 22px;
  top: 38px;
  transform: scale(0.75);
  transform-origin: left top 0px;
  pointer-events: none;
  user-select: none;
  color: rgba(0, 0, 0, 0.3);
}

.material-input {
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.87);
  cursor: inherit;
  font: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.material-error {
  color: #f44336;
  a {
    color: #f44336;
    text-decoration: underline;
  }
}

.radio-group {
  input {
    margin-bottom: 0.5em;
  }
}

.decimal-list {
  /* Numbered lists like 1, 1.1, 2.2.1... */
  ol li {
    display: block;
  } /* hide original list counter */
  ol > li:first-child {
    counter-reset: item;
  } /* reset counter */
  ol > li {
    counter-increment: item;
    position: relative;
  } /* increment counter */
  ol > li:before {
    content: counters(item, '.') '. ';
    position: absolute;
    margin-right: 100%;
    right: 10px;
  } /* print counter */
}

// Datetime Picker
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: $cd-dialoglinkblue;
}

.rdtCounter .rdtBtn {
  color: #9a9a9a;
}

.rdtPicker .rdtTime {
  @media (min-width: 768px) {
    text-align: center;
  }
}

.facebook-share-preview {
  img {
    width: 600px;
    vertical-align: bottom;
  }
}

span[class*='MuiSwitch-root-'] {
  span[class*='MuiIconButton-root-'] {
    background-color: transparent;
  }
}
