@import 'variables.scss';

.facebook-login-btn,
.facebook-share-btn {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  border-radius: 2px;
  display: inline-block;
  min-width: 88px;
}

.facebook-login-btn button,
.facebook-share-btn {
  display: inline-block;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  border: 10px;
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0 16px;
  outline: none;
  font-size: inherit;
  box-sizing: border-box;
  position: relative;
  height: 36px;
  line-height: 36px;
  width: 100%;
  border-radius: 2px;
  background-color: #3f51b5;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0px;
  height: 48px;
  font-size: 18px;
  padding: 0 48px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.facebook-login-btn button:hover,
.facebook-share-btn:hover {
  background-color: #5c6bc0;
}

.facebook-share-btn {
  padding-top: 6px;
  i {
    display: none;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.dialog-btn {
  background-color: #17587d !important;
  color: #ffffff !important;
}

.white-btn {
  background-color: #ffffff !important;
  color: $cd-darkestgrey !important;
}

.white-btn-popped {
  @extend .white-btn;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.grey-btn {
  background-color: $cd-btn-grey !important;
}

.yellow-btn {
  background-color: $cd-yellow !important;
}

.btn-lg {
  height: 44px !important;
  span {
    font-size: 16px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.btn-xl {
  height: 48px !important;
  span {
    font-size: 18px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.btn-xl-long-label {
  height: 48px !important;
  span {
    font-size: 14px !important;
    line-height: 38px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.btn-long-label {
  span {
    font-size: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.btn-column {
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.btn-block {
  width: 100%;
}

.login-button {
  margin-top: 40px !important;
  width: 265px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.red-btn {
  background-color: $cd-red !important;
  color: $white !important;
}

.dialog-formal-btn {
  background-color: $cd-dialogblue !important;
  color: $white !important;
}

.uppercase-btn {
  .MuiButton-label {
    text-transform: uppercase !important;
  }
}

.semibold-btn {
  .MuiButton-label {
    font-weight: 500 !important;
  }
}
