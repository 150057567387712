.line-height-60 {
  line-height: 60px;
}

.line-height-44 {
  line-height: 44px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-40-xs {
  @media (max-width: 768px) {
    line-height: 40px;
  }
}

.line-height-30 {
  line-height: 30px;
}

.line-height-35-xs {
  @media (max-width: 768px) {
    line-height: 35px;
  }
}

.line-height-32-xs {
  @media (max-width: 768px) {
    line-height: 32px;
  }
}

.line-height-28-xs {
  @media (max-width: 768px) {
    line-height: 28px;
  }
}

.line-height-26 {
  line-height: 26px;
}

.fat-line-height {
  line-height: 1.8;
}

.reg-line-height {
  line-height: 1em !important;
}

.avenir-black {
  font-family: 'Avenir Black', sans-serif !important;
}

.page-header {
  margin-bottom: 1.5em;
}

.no-list-style-xs {
  @media (max-width: 768px) {
    list-style-type: none;
  }
}
