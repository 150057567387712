.icon-48 {
  font-size: 48px;
}

.icon-container-32 {
  width: 32px;
}

.green-check {
  color: #00c853;
  font-size: 18px;
  padding-right: 10px;
}

.centered-icon {
  position: relative;
  left: 50%;
  margin-left: -120px;
  top: 50%;
  margin-top: -276px;
}

.cut-off-icon {
  width: 1.3em !important;
}
