@import 'variables.scss';

.slick-track {
  display: flex;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.slide-overlay {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 450%;
  position: absolute;
  top: 0;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  left: 0;
  li button::before {
    font-size: 10px;
  }
}

.hero {
  .slick-slider,
  .slick-list {
    height: inherit !important;
  }
}

.testimonials {
  .slick-slider {
    background-color: $white;
    padding: 3em 4em;
    @media (max-width: 768px) {
      padding: 2em 1em;
    }
    border-radius: 18px;
    box-shadow: 0 6px 13px 0px rgba(0, 0, 0, 0.1);
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'Font Awesome 5 Pro';
    font-size: 40px;
    line-height: 1;
    color: $cd-lightlightgrey;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 10px 20px;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: $white;
    opacity: 1;
    @media (max-width: 768px) {
      font-size: 30px;
      padding: 6px 14px;
    }
  }

  .slick-prev:before {
    content: '\f104';
  }
  [dir='rtl'] .slick-prev:before {
    content: '\f104';
  }

  [dir='rtl'] .slick-next {
    left: -10px;
    top: 70px;
    right: auto;
  }
  .slick-next:before {
    content: '\f105';
    margin-left: -30px;
  }
  [dir='rtl'] .slick-next:before {
    content: '\f105';
  }
  .slider {
    width: 650px;
    margin: 0 auto;
  }
}

.clients-carousel {
  .slick-slide {
    margin: 0 20px;
  }
  .slick-list {
    margin: 0 -20px;
  }
  .slick-dots {
    bottom: -30px;
  }
}
