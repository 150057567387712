@import 'variables.scss';

.card {
  background-color: #f3f3f4;
  margin-bottom: 2em;
}

.well-card {
  background-color: transparent !important;
  padding: 12px 24px;
  height: 254px;
  @media (max-width: 768px) {
    padding: 24px;
  }
}

.card-padding {
  padding: 1.5em 3em;
}

.card-padding-xs {
  @media (max-width: 768px) {
    padding: 1.5em 1em;
  }
}

.card-top {
  padding: 10px 10px 44px;
  position: relative;
}

.card-bottom {
  padding: 44px 14px 14px;
  color: $cd-darkestgrey;
  background-color: #f3f3f4;
}

.card-date-row {
  padding: 4px 0;
  margin-bottom: 24px;
}

.card-hosting-row {
  margin-bottom: 12px;
}

.card-profile-image-container {
  width: 66px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: -33px;
  .avatar-initials {
    border-radius: 50%;
    background-color: $cd-darkestgrey;
    color: #fff;
    text-align: center;
    padding: 17px 0;
    font-size: 24px;
  }
  .avatar-image-primary {
    margin-bottom: 0;
    position: relative;
    img {
      // position: absolute;
      bottom: 0;
    }
  }
}

.convo-card {
  min-height: 314px;
  @media (min-width: 768px) {
    height: 334px;
  }
  padding: 20px;

  .card-date-row {
    width: 100%;
    text-align: center;
    color: #fff;
  }
}

.card-button-row {
  bottom: 18px;
  width: 90%;
}

.card-button {
  a {
    color: #fff;
  }
  div {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.card-inset {
  padding: 10px 20px;
  border-radius: 2px;
  background-color: #f5f5f5;
  box-shadow: inset rgba(0, 0, 0, 0.02) 0px 1px 3px, inset rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

// Ribbon
.card-ribbon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  height: 200px;
  text-align: right;
}
.card-ribbon span {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 250px;
  display: block;
  background: $cd-darkergrey;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 39px;
  right: -57px;
}

.slim-box-padding {
  padding: 5px 0;
}

.conversationCard {
  .header {
    padding: 6px 8px;
    .date {
      flex-grow: 1;
    }
    .time {
      justify-content: flex-end;
    }
  }

  .card-top {
    display: flex;
    flex-direction: column;
    height: 116px;

    .title {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }
}
