@import "variables.scss";

.react-add-to-calendar {

  color: $cd-darkgrey;

  .react-add-to-calendar__dropdown {
    ul {
      list-style: none;
      padding-left: 0;
      text-align: left;
      li {
        margin-bottom: .2em;
        a {
          color: $cd-darkgrey;
        }
        i {
          color: $cd-darkgrey;
          margin-right: 10px;
        }
      }
    }
  }
}
