.tos {
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-6 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, lower-latin) '.' counter(lst-ctn-kix_list_2-2, lower-roman) '.'
      counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.' counter(lst-ctn-kix_list_2-6, decimal) '. ';
  }
  .lst-kix_list_2-7 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, lower-latin) '.' counter(lst-ctn-kix_list_2-2, lower-roman) '.'
      counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.' counter(lst-ctn-kix_list_2-6, decimal) '.'
      counter(lst-ctn-kix_list_2-7, decimal) '. ';
  }
  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, lower-latin) '.' counter(lst-ctn-kix_list_2-2, lower-roman) '.'
      counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '. ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, lower-latin) '.' counter(lst-ctn-kix_list_2-2, lower-roman) '.'
      counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '. ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, lower-latin) '.' counter(lst-ctn-kix_list_2-2, lower-roman) '.'
      counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.' counter(lst-ctn-kix_list_2-6, decimal) '.'
      counter(lst-ctn-kix_list_2-7, decimal) '.' counter(lst-ctn-kix_list_2-8, decimal) '. ';
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }
  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  .lst-kix_list_1-0 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '. ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '. ';
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
      counter(lst-ctn-kix_list_1-3, decimal) '. ';
  }
  .lst-kix_list_1-4 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
      counter(lst-ctn-kix_list_1-3, decimal) '.' counter(lst-ctn-kix_list_1-4, decimal) '. ';
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_1-7 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
      counter(lst-ctn-kix_list_1-3, decimal) '.' counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.' counter(lst-ctn-kix_list_1-6, decimal) '.'
      counter(lst-ctn-kix_list_1-7, decimal) '. ';
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  .lst-kix_list_1-5 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
      counter(lst-ctn-kix_list_1-3, decimal) '.' counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '. ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
      counter(lst-ctn-kix_list_1-3, decimal) '.' counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.' counter(lst-ctn-kix_list_1-6, decimal) '. ';
  }
  .lst-kix_list_2-0 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '(' counter(lst-ctn-kix_list_2-1, lower-latin) ') ';
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }
  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }
  .lst-kix_list_1-8 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
      counter(lst-ctn-kix_list_1-3, decimal) '.' counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.' counter(lst-ctn-kix_list_1-6, decimal) '.'
      counter(lst-ctn-kix_list_1-7, decimal) '.' counter(lst-ctn-kix_list_1-8, decimal) '. ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '(' counter(lst-ctn-kix_list_2-2, lower-roman) ') ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, lower-latin) '.' counter(lst-ctn-kix_list_2-2, lower-roman) '.'
      counter(lst-ctn-kix_list_2-3, decimal) '. ';
  }
  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c11 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c22 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c23 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c8 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Times';
    font-style: normal;
  }
  .c17 {
    color: #000000;
    font-weight: 400;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c9 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-indent: 36pt;
    text-align: justify;
    height: 11pt;
  }
  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-indent: 36pt;
    text-align: justify;
  }
  .c16 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Times New Roman';
  }
  .c19 {
    color: #000000;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Times';
    font-style: normal;
  }
  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
    height: 11pt;
  }
  .c21 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: center;
  }
  .c13 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: justify;
  }
  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c27 {
    background-color: #ffffff;
    max-width: 490.5pt;
    padding: 57.6pt 58.5pt 72pt 63pt;
  }
  .c7 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
  }
  .c24 {
    border: 1px solid black;
    margin: 5px;
  }
  .c28 {
    orphans: 2;
    widows: 2;
  }
  .c26 {
    color: inherit;
    text-decoration: inherit;
  }
  .c1 {
    font-weight: 700;
    font-style: italic;
  }
  .c18 {
    color: #0563c1;
  }
  .c6 {
    font-weight: 700;
  }
  .c25 {
    color: #000000;
  }
  .c14 {
    text-indent: 36pt;
  }
  .c20 {
    height: 11pt;
  }
  .c15 {
    font-style: italic;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: 'Georgia';
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Times New Roman';
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Times New Roman';
  }
  h1 {
    padding-top: 0pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: 'Times New Roman';
    line-height: 2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h2 {
    padding-top: 2pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h3 {
    padding-top: 2pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: 'Times New Roman';
    line-height: 2;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
}
