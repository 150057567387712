.img-responsive {
  max-width: 100% !important;
  height: auto;
  display: block;
}

.partner-logo-container {
  max-width: 300px;
}

.blacknwhite {
  filter: grayscale(100%);
}

.stretch-height {
  height: stretch;
}
