@import 'variables.scss';
@import 'MyFontsWebfontsKit.scss';

@font-face {
  font-family: 'ArcherMedium';
  src: local('ArcherMedium'), url(../fonts/Archer/Archer_Medium.otf) format('opentype');
}

.home {
  .blue-pane {
    background-color: $cd-darkblue;
    color: #fff;
  }
  .black-pane {
    background-color: $cd-darkestgrey;
    color: #fff;
  }
  .grey-pane {
    background-color: $cd-lightlightgrey;
    color: #fff;
  }
  .yellow-pane {
    background-color: $cd-yellow;
    color: #fff;
    a {
      color: #fff;
    }
  }
}

// Mailchimp form
#mc_embed_signup {
  form {
    text-align: right;
    padding: 10px 0 10px 0;
    display: block;
    margin-top: 0em;
  }
  input.email {
    font-size: 18px;
    font-family: 'Avenir', sans-serif;
    border: 1px solid $cd-lightlightgrey;
    border-radius: 4px;
    height: 52px;
    width: 50%;
    padding: 1em;
    display: inline-block;
    vertical-align: top;
    margin: 0 1em 0 0;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    @media (max-width: 768px) {
      width: 100%;
      padding-left: 25%;
      margin-bottom: 1em;
    }
    &::placeholder {
      color: $cd-darkgrey;
    }
    &:focus {
      outline: none;
    }
  }
  .clear {
    width: 30%;
    display: inline-block;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .button {
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    background-color: $cd-yellow;
    border-radius: 0;
    height: 52px;
    width: 100%;
    display: inline-block;
    border: none;
    color: $cd-darkestgrey;
    box-sizing: border-box;
    line-height: 32px;
    padding: 0 18px;
    margin: 0;
    font-weight: 700;
    transition: all 0.23s ease-in-out 0s;
    &:focus {
      outline: none;
    }
  }
}

.hero {
  width: 100%;
  height: 680px;
  background-color: $cd-darkestgrey;
}

.hero-cta {
  top: 30% !important;
  left: 10%;
  .row {
    width: 490px;
  }
  .hero-cta-description {
    margin-left: 0;
  }
  .hero-cta-buttons {
    width: 1000px;
  }
  @media (max-width: 768px) {
    top: 25% !important;
    left: auto;
    .row {
      width: 100%;
    }
    .hero-cta-buttons {
      width: 100%;
    }
  }
}

.client-header-container {
  @media (max-width: 768px) {
    max-width: 250px;
  }
}
