.public_fixedDataTable_main {
  border: 0;
}

.public_fixedDataTable_header, .public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-image: none;
  background-color: #fff;
  font-weight: normal;
  border-color: #fff;
  border-bottom-color: #e0e0e0;
  font-size: 12px;
  color: #9e9e9e;
}

.fixedDataTableRowLayout_rowWrapper {
  font-size: 13px;
}

.public_fixedDataTableRow_fixedColumnsDivider {
  border: none;
}

.public_fixedDataTableCell_main {
  border: none;
}

/*.public_fixedDataTableRow_highlighted, .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {*/
  /*background-color: #fff;*/
/*}*/

.public_fixedDataTable_hasBottomBorder {
  border: none;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
  border: none;
}

.basic-table {
  border: 1px solid black;
  border-collapse: collapse;
  table-layout: fixed;

  th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 15px;
  }
  .ten {
    width: 10%;
  }
}

.row-size-16 {
  th, td {font-size: 16px !important;}
}
