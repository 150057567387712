.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.row {
  padding-right: 2em;
  padding-left: 2em;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

.no-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-gutters-xs {
  @media (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.thin-gutters {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.thin-gutters-xs {
  @media (max-width: 768px) {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

.reg-gutters {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.fat-gutters-xs {
  @media (max-width: 768px) {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}

.xtra-fat-gutters-xs {
  @media (max-width: 768px) {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }
}

.thin-left-gutter {
  padding-left: 0.5em !important;
}

.no-left-gutter {
  padding-left: 0 !important;
}

.no-right-gutter {
  padding-right: 0 !important;
}

.no-right-gutter-sm {
  @media (min-width: 768px) {
    padding-right: 0 !important;
  }
}

.no-left-gutter-xs {
  @media (max-width: 768px) {
    padding-left: 0 !important;
  }
}

.no-left-gutter-sm {
  @media (min-width: 768px) {
    padding-left: 0 !important;
  }
}

.content-cage-fat {
  padding-left: 80px !important;
  padding-right: 80px !important;
  @media (max-width: 768px) {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

.content-cage-big {
  padding-left: 120px !important;
  padding-right: 120px !important;
  @media (max-width: 768px) {
    padding-left: 0.2em !important;
    padding-right: 0.2em !important;
  }
}

.content-cage {
  padding-left: 220px !important;
  padding-right: 220px !important;
  @media (max-width: 768px) {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

.content-cage-xs {
  @media (max-width: 768px) {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}

.content-cage-slim {
  padding-left: 320px !important;
  padding-right: 320px !important;
  @media (max-width: 768px) {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

.xs-hide {
  display: none;
}

.sm-show {
  @media (min-width: 768px) {
    display: block;
  }
}

.xs-block {
  @media (max-width: 768px) {
    display: block;
  }
}

.slim-rows {
  .row {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.reg-row-xs {
  @media (max-width: 768px) {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}

.fat-row {
}

.infinity-sides {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: none !important;
}

.infinity-sides-xs {
  @media (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: none !important;
  }
}

// for use with infinity sides
.reg-column-sm {
  @media (min-width: 768px) {
    padding: 2em 6em;
  }
}

.float-right {
  float: right;
}

.flex-center-sm {
  @media (min-width: 768px) {
    justify-content: center;
  }
}

.min-content-height {
  min-height: 500px;
}

.no-right {
  right: 0;
}

.col-sm-offset-0 {
  @media (min-width: 48em) {
    margin-left: 0%;
  }
}
