@import 'variables.scss';

.toolbar-nav {
  height: 76px !important;
}

.toolbar-nav > div > a {
  color: #777;
  text-decoration: none;
}

.toolbar-nav > a:hover {
  text-decoration: none;
}

.toolbar-session > div {
  cursor: pointer;
}

.toolbar-session > li > a:hover {
  text-decoration: none;
}

.toolbar-main-links {
  margin-right: 1em;
}

.toolbar-mobile-nav button .fa {
  color: #777 !important;
}

.toolbar-nav-brand {
  max-width: 300px;
  margin-right: 20px;
  &-coco {
    max-width: 380px;
  }
}

.toolbar-nav-link-container {
  margin-right: 24px;
}

.toolbar-nav-link {
  color: rgba(0, 0, 0, 0.87) !important;
  // font-family: 'Avenir Heavy', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  letter-spacing: 0.16px !important;
  text-decoration: none !important;
  line-height: 24px;
}
.no-wrap {
  white-space: nowrap;
}

.toolbar-nav-links-admin {
  color: #777;
  text-decoration: none;
}

/* Responsive Behavior */
@media (max-width: 1054px) {
  .user-toolbar .toolbar-session,
  .user-toolbar .toolbar-nav-links,
  .toolbar-main-links {
    display: none !important;
  }
}

@media (min-width: 1054px) {
  .toolbar-mobile-nav {
    display: none !important;
  }
}

.admin-links {
  display: inherit;
}

.admin-toolbar {
  background-color: #e9e9e9 !important;
  height: 50px !important;
  box-shadow: inset 0px 0px 12px 0px #d8d8d8;
  a {
    color: $cd-darkestgrey !important;
  }
  .toolbar-nav-brand {
    font-size: 16px;
    span {
      color: $cd-darkestgrey !important;
    }
    .far {
      color: $cd-darkestgrey !important;
      font-size: 16px !important;
      margin-right: 8px !important;
      margin-left: 15px !important;
    }
  }
}
