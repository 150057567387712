.lineContainer {
  background: white;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  padding: 11px 11px 7px;
}

.barContainer {
  padding: 2px 2px 0;
}

.header {
  font-size: 12px;
}

.colorSwatch {
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 24px;
}

.colorSwatchLabel {
  color: #6d6e71;
  font-size: 10px;
}

.colorSwatchData {
  color: #212122;
  font-weight: bold;
  font-size: 10px;
}
