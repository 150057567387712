/**
 * @license
 * MyFonts Webfont Build ID 4104654, 2021-06-16T14:53:11-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaNeueLTStd-UltLt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-25-ultra-light-189171/
 *
 * Webfont: HelveticaNeueLTStd-UltLtIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-26-ultra-light-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Th by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-35-thin-189171/
 *
 * Webfont: HelveticaNeueLTStd-ThIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-36-thin-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Lt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-45-light-189171/
 *
 * Webfont: HelveticaNeueLTStd-LtIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-46-light-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-55-roman-189171/
 *
 * Webfont: HelveticaNeueLTStd-It by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-56-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Md by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-65-medium-189171/
 *
 * Webfont: HelveticaNeueLTStd-MdIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-66-medium-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Bd by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-75-bold-189171/
 *
 * Webfont: HelveticaNeueLTStd-BdIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-76-bold-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Hv by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-85-heavy-189171/
 *
 * Webfont: HelveticaNeueLTStd-HvIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-86-heavy-italic-189171/
 *
 * Webfont: HelveticaNeueLTStd-Blk by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-95-black-189171/
 *
 * Webfont: HelveticaNeueLTStd-BlkIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-96-black-italic-189171/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3ea1ce');

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 100;
  src: url(../fonts/webFonts/HelveticaNeueLTStdUltLt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdUltLt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 100;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdUltLtIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdUltLtIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  src: url(../fonts/webFonts/HelveticaNeueLTStdTh/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdTh/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdThIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdThIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  src: url(../fonts/webFonts/HelveticaNeueLTStdLt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdLt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdLtIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdLtIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400, normal;
  src: url(../fonts/webFonts/HelveticaNeueLTStdRoman/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdRoman/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400, normal;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  src: url(../fonts/webFonts/HelveticaNeueLTStdMd/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdMd/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdMdIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdMdIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 600;
  src: url(../fonts/webFonts/HelveticaNeueLTStdBd/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdBd/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 600;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdBdIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdBdIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  src: url(../fonts/webFonts/HelveticaNeueLTStdHv/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdHv/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  font-style: italic;
  src: url(../fonts/webFonts/HelveticaNeueLTStdHvIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdHvIt/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 800;
  src: url(../fonts/webFonts/HelveticaNeueLTStdBlk/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdBlk/font.woff) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 800;
  font-style: italic;
  src: local('Helvetica Neue'), url(../fonts/webFonts/HelveticaNeueLTStdBlkIt/font.woff2) format('woff2'), url(../fonts/webFonts/HelveticaNeueLTStdBlkIt/font.woff) format('woff');
}
