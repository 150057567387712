@import "variables.scss";

.rdw-editor-toolbar {
  width: inherit;
  color: $cd-darkgrey;
  a {
    color: $cd-darkgrey;
  }
}
.rdw-editor-textarea {
  border: 1px solid #E0E0E0;
  a {
    color: $coco-light-blue;
  }
}

.public-DraftStyleDefault-block { margin: 0; }
.DraftEditor-editorContainer { padding: 20px; }
.rdw-center-aligned-block * { text-align: center !important; }

.schedule-wysiwyg {
  p { margin: 0; }
}
