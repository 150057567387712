@import 'variables.scss';

.pac-container {
  z-index: 1501 !important;
}

.location-filter-input-root {
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100% !important;
  height: 42px !important;
}

.location-filter-input {
  padding: 0px 0.5rem !important;
  background-color: rgb(255, 255, 255) !important;
  height: 100% !important;
  border: 1px solid #ced4da !important;
}
