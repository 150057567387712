$cd-red: #ee413e;
$cd-darkorange: #f06829;
$cd-lightorange: #f69220;
$cd-yellow: #f7bf14;
$cd-chartreuse: #c6ce40;
$cd-green: #8dc540;
$cd-teal: #388465;
$cd-skyblue: #1c93c5;
$cd-darkblue: #0e75bb;
$cd-purple: #5d3a92;
$cd-magenta: #91298c;
$cd-violet: #b32d73;
$cd-dialogblue: #17587d;
$cd-dialoglinkblue: #00bcd4;
$cd-gold: #f8c015;

// Grey scale
$white: #ffffff;
$cd-darkestgrey: #212121;
$cd-darkgrey: #212121;
$cd-darkergrey: #555658;
$cd-grey: #707174;
$cd-lightergrey: #898a8e;
$cd-lightgrey: #a9adb2;
$cd-lightlightgrey: #cdcfd2;
$cd-lightestgrey: #dfe0e2;
$cd-warmerlightgrey: #e9e9e9;
$cd-btn-grey: #00000014;
$black: #000000;

// Community Conversations
$coco-green: #007e59;
$coco-minty: #d2e6e1;
$coco-light-blue: #677b8c;

//$coco-darker-grey: #dcddf;
$coco-medium-grey: #a8abac;
$coco-light-grey: #dbdcdf;
$coco-lightest-grey: #f4f5f5;
