.spinner-page {
  margin-top: 100px;
}

.page-spinner-placeholder {
  margin-top: 100px;
  margin-bottom: 650px;
}

.shimmer {
  * {
    box-sizing: border-box;
  }

  .shimmer-container {
    display: flex;
    flex-direction: column;
  }

  .shimmer-content {
  }

  .shimmer-animated {
    background-color: #f3f3f3;
    //background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 77%, #f6f7f9 100%);
    -webkit-animation: placeholderShimmer 2s linear 0s infinite normal forwards;
    animation: placeholderShimmer 2s linear 0s infinite normal forwards;
  }

  .shimmer-box {
    position: relative;
    height: 340px;
  }
  .shimmer-box:after {
    position: absolute;
    right: 0;
    content: "";
    background: #fff;
    height: 340px;
    width: 0%;
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 500px 0;
    }
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 500px 0;
    }
  }
}
