@import '../variables.scss';

// replace when we introduce more custom themeing (button color etc)

.coco-lightest-grey {
  background-color: $coco-lightest-grey !important;
}

.coco-light-grey {
  background-color: $coco-light-grey !important;
}

.coco-medium-grey {
  background-color: $coco-medium-grey !important;
}

.coco-green {
  background-color: $coco-green !important;
}

.coco-light-blue {
  background-color: $coco-light-blue !important;
}

.coco-light-blue-text {
  color: $coco-light-blue !important;
  a {
    color: $coco-light-blue !important;
  }
}

.coco-light-grey-pane {
  background-color: $coco-light-grey !important;
  color: #212122;
}

@font-face {
  font-family: 'OptimisticDisplay';
  src: local('OptimisticDisplay'), url(../../fonts/OptimisticDisplay/Optimistic_Display_W_Md.woff) format('woff');
}
@font-face {
  font-family: 'FacebookReader';
  src: local('FacebookReader'), url(../../fonts/FacebookReader/FacebookReader-Regular.woff) format('woff');
}

.community_conversations {
  .body {
    font-family: 'OptimisticText', Arial, sans-serif;
  }
  .partners-show,
  .topics-show,
  .conversations-show,
  .conversations-new,
  .convo-card,
  .dialog,
  .admin {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'OptimisticDisplay', Arial, sans-serif !important;
    }
    p,
    div {
      font-family: 'OptimisticText', Arial, sans-serif !important;
    }
    h1,
    h2,
    h3,
    h4 {
      line-height: 1.2 !important;
    }
    h5,
    p {
      line-height: 1.5 !important;
    }
    a {
      color: $coco-light-blue !important;
    }
  }
}

.inclusivv {
  .link-text {
    color: $cd-dialoglinkblue !important;
  }
  .header-section {
    background-color: #212122;
    color: #ffffff;
  }
  .header-section-grey {
    background-color: #e6e7e7;
    color: #808080;
  }
}

.community_conversations {
  .link-text {
    @extend .coco-light-blue-text;
  }

  .coco-green-text {
    color: $coco-green !important;
  }

  .coco-minty {
    background-color: $coco-minty !important;
  }

  .header-section {
    @extend .coco-minty;
    @extend .coco-green-text;
  }

  .coco-session-btn {
    background-color: $white !important;
    @extend .coco-green-text;
  }
}
