@import 'variables.scss';
/// src : https://css-tricks.com/snippets/sass/viewport-sized-typography-minimum-maximum-sizes/
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

.header-64 {
  // font-size: 64px;
  @include responsive-font(7vw, 50px, 70px, 64px);
}

.header-56 {
  font-size: 56px !important;
}

.header-48 {
  font-size: 48px !important;
}

.header-46 {
  font-size: 46px !important;
}

.header-44 {
  font-size: 42px !important;
}

.header-42 {
  font-size: 42px;
}

.header-40 {
  font-size: 40px !important;
}

.header-38 {
  font-size: 38px !important;
}

.header-36 {
  font-size: 36px !important;
}

.header-32 {
  font-size: 32px;
}

.header-30 {
  font-size: 30px !important;
}

.header-28 {
  font-size: 28px;
}

.header-26 {
  font-size: 26px;
}

.header-24 {
  font-size: 24px;
}

.header-22 {
  font-size: 22px;
}

.header-21 {
  font-size: 21px;
}

.header-20 {
  font-size: 20px;
}

.header-18 {
  font-size: 18px !important;
}

.header-17 {
  font-size: 17px;
}

.header-16 {
  font-size: 16px !important;
}

.header-14 {
  font-size: 14px !important;
}

.header-12 {
  font-size: 12px !important;
}

.header-14-xs {
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

.header-16-xs {
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.header-18-xs {
  @media (max-width: 768px) {
    font-size: 18px !important;
  }
}

.header-21-xs {
  @media (max-width: 768px) {
    font-size: 21px !important;
  }
}

.header-24-xs {
  @media (max-width: 768px) {
    font-size: 24px !important;
  }
}

.header-25-xs {
  @media (max-width: 768px) {
    font-size: 25px !important;
  }
}

.header-26-xs {
  @media (max-width: 768px) {
    font-size: 26px !important;
  }
}

.header-28-xs {
  @media (max-width: 768px) {
    font-size: 28px !important;
  }
}

.header-32-xs {
  @media (max-width: 768px) {
    font-size: 32px !important;
  }
}

.header-36-xs {
  @media (max-width: 768px) {
    font-size: 36px !important;
  }
}

.header-40-xs {
  @media (max-width: 768px) {
    font-size: 40px !important;
  }
}

.header-28-xs {
  @media (max-width: 768px) {
    font-size: 28px !important;
  }
}

.header-24-sm {
  @media (max-width: 768px) {
    font-size: 24px !important;
  }
}

.header-20-sm {
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.header-18-sm {
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.header-14-sm {
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.header-12-sm {
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.line-height-reg {
  line-height: 1.2em;
}

.line-height-20 {
  line-height: 20px;
}

.dividing-header {
  position: relative;
  text-align: center;
  margin-top: 0;
}

.dividing-header:before {
  content: '';
  display: block;
  border-top: 1px solid $cd-lightgrey;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.dividing-header span {
  background: #f5f5f5;
  padding: 0 20px;
  position: relative;
  z-index: 5;
  color: $cd-lightgrey;
}
