@import 'variables.scss';

.avatar {
  img {
    border-radius: 50%;
  }
  span {
    fontsize: 80px;
  }
}

.avatar-image-thumb {
  width: 38px !important;
  height: 38px !important;
  &.material-icons {
    font-size: 38px !important;
  }
}

.avatar-image-medium {
  margin-bottom: 10px;
  img {
    width: 100%;
    max-width: 48px;
    vertical-align: bottom;
  }
  span {
    font-size: 48px !important;
  }
}

.avatar-image-primary {
  margin-bottom: 10px;
  img {
    width: 100%;
    max-width: 82px;
    vertical-align: bottom;
  }
  span {
    font-size: 80px !important;
    color: rgba(0, 0, 0, 0.37) !important;
  }
}

.avatar-image-profile-primary {
  margin-bottom: 10px;
  img {
    width: 100%;
    max-width: 82px;
    vertical-align: bottom;
  }
  span {
    font-size: 140px !important;
  }
}

.avatar-nav {
  display: inline-block;
  img {
    vertical-align: bottom;
  }
  span {
    opacity: 1;
  }
}

.avatar-name {
  margin-left: 10px;
  color: #00bcd4;
  vertical-align: super;
}

.fa-user-circle {
  color: $cd-lightlightgrey;
}

.resize-to-fill {
  &-primary {
    height: 82px;
    width: 82px;
  }
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}
