.partners-show {
  .hero-pane {
    background-position: 100% 75%;
    background-repeat: no-repeat;
    background-size: 100%;
    color: #fff;
    background-color: #fff;
    padding-top: 365px;
    @media (max-width: 768px) {
      padding: 7em 0 0;
    }
  }
}

.conversations-show {
  .hero-pane {
    background-position: 100% 75%;
    background-repeat: no-repeat;
    background-size: 100%;
    color: #fff;
    background-color: #fff;
  }
  .imaged-hero-pane {
    padding-top: 365px;
    @media (max-width: 768px) {
      padding: 7em 0 0;
    }
  }
  //.partnership-pane {
  //  background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/facebookEMEA/partnership_image.jpg) no-repeat 100% 75%;
  //  background-size: 100%;
  //  color: #fff;
  //  padding-top: 320px;
  //  @media (max-width: 768px) {
  //    padding: 7em 1.5em 0;
  //  }
  //}
}

.ARC {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/arc_hero_2552.jpg)
      no-repeat 100% 75%;
    background-size: 100%;
    color: #fff;
    padding-top: 320px;
    @media (max-width: 768px) {
      padding: 7em 1.5em 0;
    }
  }
}

.NetImpact {
  .hero-pane {
    background: url(https://s3.amazonaws.com/civicdinners-manual-storage/netimpact-background.jpg)
      no-repeat 100% 62%;
    background-size: 100%;
    color: #fff;
    padding-top: 240px;
    @media (max-width: 768px) {
      padding: 6em 1.5em 0;
    }
  }
}

.handsonatlanta {
  .hero-pane {
    background: url(https://s3.amazonaws.com/civicdinners-manual-storage/partners/handsonatlanta/background.png)
      no-repeat 100% 62%;
    background-size: 100%;
    color: #fff;
    padding-top: 240px;
    @media (max-width: 768px) {
      padding: 3em 1.5em 0;
    }
  }
}

.DecaturGA {
  .hero-pane {
    background: url(https://s3.amazonaws.com/civicdinners-manual-storage/partners/DecaturGA/background.jpg)
      no-repeat 100% 5%;
    background-size: 100%;
    color: #fff;
    padding-top: 240px;
    @media (max-width: 768px) {
      padding: 3em 11em 0;
    }
  }
}

.GA-DFCS {
  .hero-pane {
    background: url(https://s3.amazonaws.com/civicdinners-manual-storage/partners/GA-DFCS/background.jpg)
      no-repeat 50% 5%;
    background-size: 100%;
    color: #fff;
    padding-top: 290px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 2em 11em 0;
    }
  }
}

.BCT {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/BCT/background.jpg)
      no-repeat 50% 5%;
    background-size: 100%;
    color: #fff;
    padding-top: 290px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 2em 11em 0;
    }
  }
}

.SDDCELL {
  .hero-pane {
    background: url(https://s3.amazonaws.com/civicdinners-manual-storage/partners/SDDCELL/background.png)
      no-repeat 50% 5%;
    background-size: 100%;
    color: #fff;
    padding-top: 290px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 2em 11em 0;
    }
  }
}

.ConsciousCap {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/ConsciousCap/background_2.png)
      no-repeat;
    background-size: 100%;
    color: #fff;
    padding-top: 310px;
    @media (max-width: 768px) {
      background-size: 200%;
      padding: 8em 0 0 0;
      background-position: right;
    }
  }
  .partner-profile-image {
  }
}

.TFA-MetroAtlanta {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/TFA-MetroAtlanta/background-image.jpg)
      no-repeat 50% 5%;
    background-size: 100%;
    color: #fff;
    padding-top: 290px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 2em 11em 0;
    }
  }
}

.Miami-DadeTPO {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/Miami-DadeTPO/miami-background.jpg)
      no-repeat 50% 5%;
    background-size: 100%;
    color: #fff;
    padding-top: 350px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 9em 0 0;
    }
  }
}

.up-to-us {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/up-to-us/background_image.jpg)
      no-repeat 50% 83%;
    background-size: 100%;
    color: #fff;
    padding-top: 350px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 9em 0 0;
    }
  }
}

.shemeansbusiness {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/shemeansbusiness/background-image.png)
      no-repeat 50% 30%;
    background-size: 100%;
    color: #fff;
    padding-top: 310px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 9em 0 0;
    }
  }
}

.sage {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/sage/background-image.jpg)
      no-repeat 50% 60%;
    background-size: 100%;
    color: #fff;
    padding-top: 310px;
    @media (max-width: 768px) {
      background-size: 140%;
      padding: 9em 0 0;
    }
  }
}

.conversation-id-742 {
  background: url(https://civicdinners-manual-storage.s3.amazonaws.com/conversations/background-742.png)
    no-repeat 50% 30%;
  background-size: 100%;
  color: #fff;
  @media (max-width: 768px) {
    background-size: 140%;
  }
}

.NAMI-GA {
  .hero-pane {
    background: url(https://civicdinners-manual-storage.s3.amazonaws.com/partners/NAMI-GA/background.jpg)
      no-repeat 100% 62%;
    background-size: 100%;
    color: #fff;
    padding-top: 240px;
    @media (max-width: 768px) {
      padding: 6em 1.5em 0;
    }
  }
}

.thelovablecity {
  .hero-header {
    @media (max-width: 768px) {
      color: transparent;
    }
  }
}
