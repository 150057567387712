@import 'variables.scss';

body {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;

  //expose the grid
  // * {
  //   border: 1px solid hotpink;
  // }
}

// Fix for inputs not scrolling on mobile
html,
body {
  -webkit-overflow-scrolling: touch !important;
  //overflow: auto !important;
  height: 100% !important;
}

a {
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

p.bring-closer {
  margin-top: 0;
  margin-bottom: 0.4em;
}

.no-margin {
  margin: 0 !important;
}

.reg-padding {
  padding: 1em;
}

.flat {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.flat-bottom {
  margin-bottom: 0;
}

.flat-bottom-xs {
  @media (max-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.tiny-bottom-sm {
  @media (min-width: 768px) {
    margin-bottom: 0.2em !important;
  }
}

.reg-bottom {
  margin-bottom: 1em !important;
}

.thin-bottom {
  margin-bottom: 0.5em !important;
}

.thin-bottom-xs {
  @media (max-width: 768px) {
    margin-bottom: 0.5em !important;
  }
}

.reg-fat-bottom {
  margin-bottom: 1.2em !important;
}

.fat-bottom {
  margin-bottom: 1.5em !important;
}

.xtra-fat-bottom {
  margin-bottom: 2em !important;
}

.huge-top {
  margin-top: 3em !important;
}

.huge-top-xs {
  @media (max-width: 768px) {
    margin-top: 3em !important;
  }
}

.huge-bottom {
  margin-bottom: 3em !important;
}

.xtra-huge-bottom {
  margin-bottom: 5em !important;
}

.xtra-huger-bottom {
  margin-bottom: 7em !important;
}

.xtra-huger-bottom-sm {
  @media (min-width: 768px) {
    margin-bottom: 7em !important;
  }
}

.hugest-bottom {
  margin-bottom: 16em !important;
}

.hugest-bottom-padding {
  padding-bottom: 16em !important;
}

.blank-page {
  margin-bottom: 52em !important;
}

.reg-bottom-xs {
  @media (max-width: 768px) {
    margin-bottom: 1em !important;
  }
}

.fat-bottom-xs {
  @media (max-width: 768px) {
    margin-bottom: 1.5em;
  }
}

.xtra-fat-bottom-xs {
  @media (max-width: 768px) {
    margin-bottom: 2em;
  }
}

.huge-bottom-xs {
  @media (max-width: 768px) {
    margin-bottom: 3em;
  }
}

.huge-bottom-sm {
  @media (min-width: 768px) {
    margin-bottom: 3em;
  }
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.reg-margin {
  margin: 1em !important;
}

.reg-top {
  margin-top: 1em !important;
}

.reg-top-xs {
  @media (max-width: 768px) {
    margin-top: 1em !important;
  }
}

.thin-top {
  margin-top: 0.5em !important;
}

.big-top {
  margin-top: 1.5em !important;
}

.fat-top {
  margin-top: 2em !important;
}

.flat-top {
  margin-top: 0;
}

.flat-top-xs {
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.fat-top-xs {
  @media (max-width: 768px) {
    margin-top: 1.5em;
  }
}

.xtra-fat-top {
  margin-top: 2em !important;
}

.giant-top {
  margin-top: 4em !important;
}

.giant-top-sm {
  @media (min-width: 768px) {
    margin-top: 4em !important;
  }
}

.flat-right {
  margin-right: 0 !important;
}

.thin-right {
  margin-right: 0.5em !important;
}

.reg-right {
  margin-right: 1em !important;
}

.thin-right-xs {
  @media (max-width: 768px) {
    margin-right: 0.5em !important;
  }
}

.massive-left-padding {
  padding-left: 10em !important;
}

.huge-left-padding {
  padding-left: 5em !important;
}

.huge-left-padding-sm {
  @media (min-width: 768px) {
    padding-left: 5em !important;
  }
}

.massive-right-padding {
  padding-right: 10em !important;
}

.huge-right-padding {
  padding-right: 5em !important;
}

.huge-right-padding-sm {
  @media (min-width: 768px) {
    padding-right: 5em !important;
  }
}

.fat-right-xs {
  @media (max-width: 768px) {
    margin-right: 2em !important;
  }
}

.fat-right-padding {
  padding-right: 3em !important;
}

.fat-right-padding-sm {
  @media (min-width: 768px) {
    padding-right: 3em !important;
  }
}

.reg-right-padding {
  padding-right: 2em !important;
}

.thin-right-padding {
  padding-right: 0.5em !important;
}

.flat-right-padding {
  padding-right: 0 !important;
}

.fat-left {
  margin-left: 2em !important;
}

.fat-left-xs {
  @media (max-width: 768px) {
    margin-left: 2em !important;
  }
}

.reg-left {
  margin-left: 1em !important;
}

.thin-left {
  margin-left: 0.5em !important;
}

.flat-left {
  margin-left: 0 !important;
}

.thin-left-xs {
  @media (max-width: 768px) {
    margin-left: 0.5em !important;
  }
}

.flat-left-padding {
  padding-left: 0 !important;
}

.thin-left-padding {
  padding-left: 0.5em !important;
}

.fat-left-padding {
  padding-left: 3em !important;
}

.fat-left-padding-sm {
  @media (min-width: 768px) {
    padding-left: 3em !important;
  }
}

.reg-left-padding {
  padding-left: 2em !important;
}

.fat-padding {
  padding: 2em !important;
}

.reg-left-padding-sm {
  @media (min-width: 768px) {
    padding-left: 2em !important;
  }
}

.flat-top-padding {
  padding-top: 0em !important;
}

.flat-bottom-padding {
  padding-bottom: 0 !important;
}

.flat-bottom-padding-sm {
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
}

.flat-bottom-padding-xs {
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}

.fat-top-padding-sm {
  @media (min-width: 768px) {
    padding-top: 2em !important;
  }
}

.huge-top-padding-sm {
  @media (min-width: 768px) {
    padding-top: 4em !important;
  }
}

.huge-top-padding {
  padding-top: 5em !important;
}

.fat-top-padding {
  padding-top: 2em !important;
}

.reg-top-padding {
  padding-top: 1em !important;
}

.fat-bottom-padding {
  padding-bottom: 2em !important;
}

.reg-bottom-padding {
  padding-bottom: 1em !important;
}

.thin-top-padding {
  padding-top: 0.5em !important;
}

.thin-bottom-padding {
  padding-bottom: 0.5em !important;
}

.tiny-bottom-padding {
  padding-bottom: 0.2em !important;
}

.big-top-padding {
  padding-top: 1.5em !important;
}

.big-bottom-padding {
  padding-bottom: 1.5em !important;
}

.thin-top-padding-xs {
  @media (max-width: 768px) {
    padding-top: 0.5em !important;
  }
}

.reg-top-padding-xs {
  @media (max-width: 768px) {
    padding-top: 1em !important;
  }
}

.xtra-fat-bottom-padding {
  padding-bottom: 3em !important;
}

.xtra-fat-bottom-padding-sm {
  @media (max-width: 768px) {
    padding-bottom: 3em !important;
  }
}

.thin-padding {
  padding: 0.5em !important;
}

.tiny-padding {
  padding: 0.2em !important;
}

.no-padding {
  padding: 0 !important;
}

.quite-big-bottom-padding {
  padding-bottom: 8em !important;
}

.center-margin {
  margin: 0 auto !important;
}

.center-margin-xs {
  @media (max-width: 768px) {
    margin: 0 auto !important;
  }
}

.hide,
.visible-false {
  display: none !important;
}

.display-contents {
  display: contents !important;
}

.show-sm {
  @media (min-width: 768px) {
    display: block !important;
  }
}

.hide-sm {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.inline-block-container {
  * {
    display: inline-block;
  }
}

.align-bottom {
  vertical-align: bottom;
}

.align-top {
  vertical-align: top !important;
}

.align-super {
  vertical-align: super;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right !important;
}

.align-right-sm {
  @media (min-width: 768px) {
    text-align: right !important;
  }
}

.align-center-xs {
  @media (max-width: 768px) {
    text-align: center;
  }
}

.align-left {
  text-align: left !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.italic {
  font-style: italic;
}

.red-text {
  color: $cd-red !important;
}

.white-text {
  color: #fff !important;
}

.white-text-xs {
  @media (max-width: 768px) {
    color: #fff !important;
  }
}

.black-text {
  color: $cd-darkestgrey !important;
}

.black-link {
  a {
    color: $cd-darkestgrey !important;
  }
}

.dark-grey-text {
  color: $cd-darkgrey;
}

.darker-grey-text {
  color: $cd-darkergrey !important;
}

.light-grey-text {
  color: $cd-lightgrey !important;
}

.light-light-grey-text {
  color: $cd-lightlightgrey !important;
}

.skyblue-text {
  color: $cd-skyblue !important;
}

.magenta-text {
  color: $cd-magenta !important;
}

.violet-text {
  color: $cd-violet !important;
}

.purple-text {
  color: $cd-purple !important;
}

.teal-text {
  color: $cd-teal !important;
}

.gold-text {
  color: #cb9b2b !important;
}

.blue-text {
  color: $cd-dialogblue !important;
}

.blue-link-text {
  color: $cd-dialoglinkblue !important;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.semibold {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.strongbold {
  font-weight: 800 !important;
}

button.bold {
  span {
    font-weight: 700 !important;
  }
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-3 {
  opacity: 0.3;
}

.hover-opaque {
  &:hover {
    opacity: 1;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed-body-for-ios {
  position: fixed;
  width: 100%;
}

.fixed-bottom {
  position: fixed;
  bottom: 4.25rem;

  @media (max-width: 768px) {
    bottom: 7.25rem;
  }
}

.half-size {
  padding-right: 50%;
}

.underline {
  text-decoration: underline !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline !important;
  }
}

.weak-underline-sm {
  @media (min-width: 768px) {
    border-bottom: 1px solid $cd-lightlightgrey;
  }
}

// not sure where to put these right now

.partner-profile-image {
  max-width: 200px;
  background-color: #ffffff;
  max-height: 200px;
  border-radius: 50%;
  bottom: -70px;
  right: 50px;
  z-index: 10;
  img {
    border-radius: 50%;
  }
}

.convo-icon {
  max-width: 120px;
}

.convo-icon-sm {
  max-width: 80px !important;
}

.convo-icon-xs {
  max-width: 60px !important;
}

.max-width {
  width: 100%;
}

.min-max-width {
  min-width: 100%;
}

.max-height {
  height: 100% !important;
}

.height-inherit {
  height: inherit !important;
}

.top-right-position {
  top: -26px;
  right: -10px;
}

.vertical-bottom {
  vertical-align: bottom;
}

.dashed-border {
  border-style: dashed;
  border-width: 5px;
}

.lightest-grey-border {
  border-color: $cd-lightestgrey;
}

.lightest-grey-bottom-border {
  border-bottom: 1px solid $cd-lightestgrey;
}

.white-background {
  background-color: #fff;
}

.red-background {
  background-color: $cd-red !important;
}

.magenta-background {
  background-color: $cd-magenta !important;
}

.purple-background {
  background-color: $cd-purple !important;
}

.violet-background {
  background-color: $cd-violet !important;
}

.pink-background {
  background-color: pink !important;
}

.darkergrey-background {
  background-color: $cd-darkergrey;
}

.lightlightgrey-background {
  background-color: $cd-lightlightgrey;
}

.lightestgrey-background {
  background-color: $cd-lightestgrey;
}

// this may be ridiculous
.width-240-sm {
  @media (min-width: 768px) {
    width: 240px !important;
  }
}

.max-60 {
  max-width: 60%;
}

.max-400 {
  max-width: 400px;
}

.max-300-xs {
  @media (max-width: 768px) {
    max-width: 300px !important;
  }
}

.max-240 {
  max-width: 240px;
}

.max-180 {
  max-width: 180px;
}

.max-180-sm {
  @media (min-width: 768px) {
    max-width: 180px !important;
  }
}

.max-160-xs {
  @media (max-width: 768px) {
    max-width: 160px !important;
  }
}

.width-60-container {
  img,
  span {
    width: 60%;
  }
}

.lightestgrey-pane .description-section a {
  font-weight: bold;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer !important;
}

.center-height-padding {
  padding: 8em 0;
}

.center-height-padding-long {
  padding: 4em 0;
}

.alert-bar {
  padding: 1em 2em;
  border-radius: 4px;
  &-notice {
    background-color: #9deaf34f;
    border: 2px solid #9deaf3eb;
  }
  &-warning {
    background-color: #f39d9d4f;
    border: 2px solid #f3a4a47d;
  }
  color: $cd-darkgrey;
}

.no-border {
  border: 0 !important;
}

.hover-item {
  cursor: pointer;
  &:hover {
    p {
      text-decoration: underline;
    }
  }
}

.letter-spaced {
  letter-spacing: 1px;
}

.no-shadow {
  box-shadow: none !important;
}
