@import 'variables.scss';

.dialog {
  max-width: 500px !important;
  width: 95% !important;
  padding: 1em;
  margin: 0 !important;
  a {
    color: #00bcd4;
  }
  @media (max-width: 768px) {
    width: 95% !important;
  }
}

.wide-dialog {
  max-width: 600px !important;
}

.thin-dialog {
  max-width: 380px !important;
  padding: 0;
}

.dialog-blue-btn {
  background-color: $cd-dialogblue !important;
  color: #ffffff;
}

.transparent-dialog-title {
  background-color: transparent !important;
  color: $cd-darkestgrey !important;
}
