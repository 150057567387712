@import 'variables.scss';

.pane {
  h1,
  h2 {
    margin-top: 0;
  }
}

.skinny-pane {
  padding: 0.5em 2em;
  @media (max-width: 768px) {
    padding: 0.5em 1em;
  }
}

.skinny-pane-xs {
  @media (max-width: 768px) {
    padding: 0.5em !important;
  }
}

.thin-pane {
  padding: 1em 2em;
  @media (max-width: 768px) {
    padding: 2em 1em;
  }
}

.thin-pane-sm {
  @media (min-width: 768px) {
    padding: 1em 2em;
  }
}

.thin-pane-xs {
  @media (max-width: 768px) {
    padding: 1em 2em !important;
  }
}

.thin-reg-pane {
  padding: 1.5em 2em;
  @media (max-width: 768px) {
    padding: 1.5em 1em;
  }
}

.trim-pane {
  padding: 1.5em;
  @media (max-width: 768px) {
    padding: 1.5em 1em;
  }
}

.reg-pane {
  padding: 2em;
  @media (max-width: 768px) {
    padding: 3em 1em;
  }
}

.reg-pane-xs {
  @media (max-width: 768px) {
    padding: 3em 1em;
  }
}

.big-pane {
  padding: 3em 2em !important;
  @media (max-width: 768px) {
    padding: 2em 1em !important;
  }
}

.fat-pane {
  padding: 4em 2em;
  @media (max-width: 768px) {
    padding: 3em 1em;
  }
}

.fat-pane-xs {
  @media (max-width: 768px) {
    padding: 4em 0.5em !important;
  }
}

.xtra-fat-pane {
  padding: 5em 2em;
  @media (max-width: 768px) {
    padding: 3em 0.5em;
  }
}

.huge-pane {
  padding: 6em 2em;
  @media (max-width: 768px) {
    padding: 4em 0.5em;
  }
}

.massive-pane {
  padding: 8em 2em;
  @media (max-width: 768px) {
    padding: 6em 0.5em;
  }
}

.clear-pane {
  h1,
  h2,
  p,
  a {
    color: $cd-darkestgrey !important;
  }
}

.lightlightgrey-pane {
  background-color: $cd-lightlightgrey;
  color: $cd-darkestgrey;
}

.lightestgrey-pane {
  background-color: $cd-lightestgrey;
  color: $cd-darkestgrey;
  a {
    color: $cd-darkestgrey !important;
  }
}

.lightergrey-pane {
  background-color: $cd-lightergrey;
  color: $cd-darkestgrey;
  a {
    color: $cd-darkestgrey !important;
  }
}

.warmerlightgrey-pane {
  background-color: $cd-warmerlightgrey;
  color: $cd-darkestgrey;
  a {
    color: $cd-darkestgrey !important;
  }
}

.minimal-grey-pane {
  background-color: #f2f3f4;
}

.darkestgrey-pane {
  background-color: $cd-darkestgrey;
  color: #ffffff;
  a {
    color: #ffffff;
  }
}

.darkestgrey-pane-xs {
  @media (max-width: 768px) {
    background-color: $cd-darkestgrey !important;
    color: #ffffff !important;
    a {
      color: #ffffff !important;
    }
  }
}

.darkgrey-pane {
  background-color: $cd-darkgrey;
  color: #ffffff;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.yellow-pane {
  background-color: $cd-yellow;
}

.gold-pane {
  background-color: $cd-gold;
  color: #ffffff;
}

.white-pane {
  background-color: #ffffff;
}

.blue-pane {
  background-color: $cd-skyblue;
}

.pane-darkened {
  background-color: rgba(0, 0, 0, 0.3);
}

.lightly-darkened-pane {
  background-color: rgba(0, 0, 0, 0.2);
}

.very-light-grey-pane {
  background-color: #f5f5f5;
}

.hero-description {
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.card-pane-sm {
  @media (min-width: 768px) {
    margin-left: 5em;
    margin-right: 5em;
    margin-top: -11em;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  }
}

.rounded-pane {
  border-radius: 18px;
}

.rounded-pane-bottom-sm {
  @media (min-width: 768px) {
    border-radius: 0 0 18px 18px;
  }
}

.transparent-header-section {
  padding: 4em 2em 2em;
  @media (max-width: 768px) {
    padding: 3em 1em 0;
  }
}
